import React, {useEffect, useState} from 'react';
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import {fetchApi} from "../utils/fetch";
import "survey-core/defaultV2.min.css";
import './survey_js_custom.css';
import '../QuizEditor.css';

function warnBeforeClosing(e) {
  e.preventDefault();
  e.returnValue = '';
}

export default function SurveyTaker({assessment, quiz: {uuid: quizId, quiz}, feedback = false}) {
  const [showSurvey, setShowSurvey] = useState(feedback);

  const {uuid: assessmentId} = assessment || {};
  const survey = new Model(quiz);

  useEffect(() => {
    if (showSurvey && !feedback) window.addEventListener('beforeunload', warnBeforeClosing);
  }, [showSurvey]);

  survey.onCompleting.add((sender, _options) => {
    window.removeEventListener('beforeunload', warnBeforeClosing);
  });

  survey.onComplete.add((sender, {showSaveError, showSaveInProgress, showSaveSuccess}) => {
    const url = assessmentId ? `/take/${assessmentId}/complete` : '/take/complete';
    showSaveInProgress('Saving your assessment, please wait...');
    fetchApi(url, {
      method: 'PATCH',
      body: JSON.stringify({
        results: sender.data,
        quiz_id: quizId,
        feedback,
      }),
    }).then(({success}) => {
      if (success) {
        showSaveSuccess('Saved!');
        if (!!quiz.navigateToUrl) setTimeout(() => window.location.href = quiz.navigateToUrl, 3000);
      } else {
        showSaveError('There was an issue saving your results...');
      }
    })
    .catch(() => {
      showSaveError('Request error: There was an issue saving your results, please click, "Try again"...');
    });
  });

  return (
    <>
      { showSurvey
        ?
          <Survey model={survey} />
        : (
          <div className="mx-auto h-full flex flex-col justify-center items-center">
            <div>
              <div className="mx-auto max-w-prose text-lg">
                <h1>
                  <span className="block text-center text-lg font-semibold text-orange-600">Coachability@Work®</span>
                  <span
                    className="mt-2 block text-center text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">Before you begin...</span>
                </h1>
                <p className="mt-8 text-xl leading-8 text-gray-500 text-center">Please try to complete this assessment in one
                  sitting.</p>
                <p className="mt-8 text-xl leading-8 text-gray-500 text-center">Also, note that if you <strong>leave this page
                  before completing</strong> the Assessment, your answers will <strong>not be saved</strong> and you'll have
                  to start over.</p>
                <button
                  className="mt-8 mx-auto block font-bold text-lg bg-blue-500 max-w-20 rounded-full text-white animate-pulse px-5 ring-4 ring-blue-800 py-3"
                  onClick={() => setShowSurvey(true)}>Begin Assessment
                </button>
              </div>
            </div>
          </div>
      ) }
    </>
  );
}
