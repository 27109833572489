import React, {useEffect, useRef, useState} from 'react';
import {useLoaderData, useNavigate} from 'react-router';
import {getSurveyAssessments} from './fetchers';
import InputDateTime from "./InputDateTime";
import {fetchApi} from './utils/fetch';
import {RadioGroup} from '@headlessui/react';
import {
  CalculatorIcon,
  ChartBarSquareIcon,
  DocumentPlusIcon,
  InformationCircleIcon,
  UserPlusIcon
} from '@heroicons/react/24/outline';
import {CheckCircleIcon} from '@heroicons/react/24/solid';
import {FileUploader} from 'react-drag-drop-files';
import dateFormat from 'dateformat';
import SurveyEndsOnRange from './SurveyEndsOnRange';
import SwitchToggle from './SwitchToggle';
import SurveyProvider from './contexts/ContextSurvey';
import {Spinner} from './Spinner';
import {classNames} from "./utils/classNames";
import {useUser} from "./contexts/ContextUser";
import SurveyTypeBadge from "./SurveyTypeBadge";
import Tippy from "@tippyjs/react";
import {AdminRequired} from "./App";

export default function SurveyNew() {
  const {user} = useUser();
  const [csvData, setCsvData] = useState({header: [], rows: []});
  const [pastSurveyAssessments, setPastSurveyAssessments] = useState([]);
  const [surveySource, setSurveySource] = useState('surveyjs');
  const [surveyStartsOn, setSurveyStartsOn] = useState(null);
  const [deadline, setDeadline] = useState(null);
  const [surveyEndsOn, setSurveyEndsOn] = useState(null);
  const [surveyAutoSendResults, setSurveyAutoSendResults] = useState(false);
  const [createEmployeeSurveys, setCreateEmployeeSurveys] = useState(true);
  const [participantsFileData, setParticipantsFileData] = useState(null);
  const {past_surveys: pastSurveys, types: surveyJsSurveyTypes = []} = useLoaderData();
  const [participantsBySurvey, setParticipantsBySurvey] = useState(null);
  const [adhocParticipants, setAdhocParticipants] = useState([]);
  const fileUploaderRef = useRef(null);
  const [selected, setSelected] = useState(null);
  const [fileOrFiles, setFile] = useState(null);
  const [csvFile, setCsvFile] = useState(null);
  const [savingSurvey, setSavingSurvey] = useState(false);
  const [startsOnMinDate, setStartsOnMinDate] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [participants, setParticipants] = useState({adhoc: [], csv: [], past: []});
  const [participantDups, setParticipantDups] = useState({});
  const [surveyName, setSurveyName] = useState(null);
  const [surveyType, setSurveyType] = useState(surveyJsSurveyTypes[0]?.type_name);
  const [selectedSurveyName, setSelectedSurveyName] = useState(surveyJsSurveyTypes[0]?.name);

  // Update auto_send_results when survey type changes
  useEffect(() => {
    if (surveyType === 'behavioral') {
      setSurveyAutoSendResults(true);
    }
  }, [surveyType]);

  const navigate = useNavigate();

  useEffect(() => {
    if (!participantsBySurvey) return;
    getSurveyAssessments(null, participantsBySurvey.id).then((assessments) => {
      setParticipants({
        ...participants,
        past: assessments.map((assessment) => ({
          ...assessment.participant,
          assessment_id: assessment.id,
          type: assessment.assessment_type,
          group: assessment.group_number,
          subject_email: assessment.subject?.email  // Include subject email for leader assessments
        })),
      });
      setPastSurveyAssessments(assessments);
    });
  }, [participantsBySurvey]);

  useEffect(() => {
    // Check for duplicates, but allow leaders to appear multiple times
    const dups = getAllParticipants().reduce((acc, participant) => {
      const key = participant.type?.toLowerCase() === 'leader' 
        ? `${participant.email}_${participant.subject_email}` // Leader + subject combo must be unique
        : participant.email;  // Regular participants must be unique by email
      
      acc[key] ||= 0;
      acc[key] += 1;

      return acc;
    }, {});

    // We need to remove reference to the "uploaded" file from the UI
    if (!participants.csv.length) setCsvFile(null);

    // We need to unset the selected survey from the UI
    if (!participants.past.length) setParticipantsBySurvey(null);

    setParticipantDups(dups);
  }, [participants.csv, participants.adhoc, participants.past]);

  useEffect(() => {
    if (Object.keys(formErrors).length) validateSteps();
  }, [participantsBySurvey, participantsBySurvey, surveyName, surveyEndsOn]);

  function getAllParticipants() {
    return [...participants.csv, ...participants.adhoc, ...participants.past]
  }

  function validateSteps() {
    const newFormErrors = {};

    if (!getAllParticipants().length) {
      newFormErrors.step2 = 'You must select participants for this survey.';
    }

    if (participantsFileData && !formErrors.step2) {
      if (csvData.rows.find((row) => !!row[5].error)) newFormErrors.step2 = 'You have errors with the CSV file you uploaded.';
    }

    if (!surveyName) {
      newFormErrors.step3 = 'You must name this survey.';
    }

    if (!surveyEndsOn) {
      newFormErrors.step4 = 'You must select a time range for the survey to expire.';
    }

    setFormErrors(newFormErrors);
    return newFormErrors;
  }

  function saveSurvey() {
    const errors = validateSteps();
    if (Object.values(participantDups).findIndex((d) => d > 1) > -1) return;
    if (Object.keys(errors).length) return errors;

    setSavingSurvey(true);

    fetchApi(`/api/v1/internal/surveys`, {method: 'POST', body: JSON.stringify({
      name: surveyName,
      deadline,
      survey_type: surveyType,
      survey_source: surveySource,
      starts_on: surveyStartsOn,
      ends_on_range: surveyEndsOn,
      auto_send_results: surveyAutoSendResults,
      participants_file_data: participantsFileData,
      participants_by_survey_id: participantsBySurvey?.id,
      past_survey_assessments: pastSurveyAssessments.map((a) => a.id),
      participants,
      create_employee_surveys: createEmployeeSurveys,
    })})
    .then(({success, error, survey}) => {
      if (!success) {
        setFormErrors({submitError: error});
        return;
      }

      // For behavioral quizzes, redirect to the survey editor
      if (surveyType === 'behavioral') {
        navigate(`/survey/${survey.id}/edit`);
      } else {
        navigate(`/survey/${survey.id}`);
      }
    })
    .finally(() => setSavingSurvey(false));
  }

  function updateParticipantsList(file) {
    const reader = new FileReader();

    reader.addEventListener('load', (event) => {
      setParticipantsFileData(event.target.result);
      const data = event.target.result.split('\n');
      const header = data[0].trim();
      const emailCounts = {};
      const rows = data.splice(1).map((row) => {
        row = row.trim();
        if (!row) return null;
        return row.split(',');
      }).filter((row) => !!row).sort((a, b) => {
        if (a[3] === b[3]) return 0;
        return a[3] < b[3] ? -1 : 1;
      }).map((row) => {
          // Track unique combinations
          const key = row[4]?.toLowerCase() === 'leader'
            ? `${row[2]}_${row[5]}` // Leader + subject combo
            : row[2];  // Regular participant email
          emailCounts[key] ||= 0;
          emailCounts[key]++;
          return row;
        }).map((row) => {
          const key = row[4]?.toLowerCase() === 'leader'
            ? `${row[2]}_${row[5]}`
            : row[2];
          row = [row[0], row[1], row[2], row[3], row[4], row[5]];
          if (!row[0] || !row[1] || !row[2] || !row[3] || !row[4]) {
            row.push({error: 'You are missing data.'});
          } else if (row[4]?.toLowerCase() === 'leader' && !row[5]) {
            row.push({error: 'Leader assessments require subject_email.'});
          } else {
            row.push({error: emailCounts[key] > 1 ? 'Duplicate assessment.' : null});
          }
          return row;
      });

      setParticipants({...participants, csv: rows.map(( row) => {
        const type = row[4]?.toLowerCase();
        return {
          first_name: row[0],
          last_name: row[1],
          email: row[2],
          group: row[3],
          type: row[4],
          subject_email: type === 'leader' ? row[5] : undefined  // Only include for leaders
        }
      })});
    });

    setCsvFile(file);

    reader.readAsText(file);
  }

  function formatDate(date) {
    return `${dateFormat(date, 'yyyy-mm-dd')}T${dateFormat(date, 'hh:mm')}`;
  }

  function removeParticipant(key, idx) {
    const newRows = [...participants[key]];
    newRows.splice(idx, 1);
    setParticipants({...participants, [key]: newRows});
  }

  function addAdhocParticipant(e) {
    e.preventDefault();

    const [first_name, last_name, email, group_number, assessment_type, subject_email] = e.target;
    const isLeader = assessment_type.value.toLowerCase() === 'leader';

    if (isLeader && !subject_email.value) {
      alert('Leader assessments require a subject email');
      return;
    }

    const newParticipant = {
      first_name: first_name.value,
      last_name: last_name.value,
      email: email.value,
      group_number: group_number.value,
      assessment_type: assessment_type.value
    };

    // Only include subject_email for leaders
    if (isLeader) {
      newParticipant.subject_email = subject_email.value;
    }

    setParticipants({
      ...participants,
      adhoc: [...participants.adhoc, newParticipant]
    });

    // Reset form input elements
    Array.from(e.target).forEach((input) => input.value = '');
  }

  const hasParticipants = !!getAllParticipants().length;

  return (
    <SurveyProvider survey={{}}>
      <div className="space-y-5 mx-5 mt-3 pb-16">
        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">Create a new survey</h2>
        <div className="border-b border-gray-200 bg-white rounded-lg overflow-hidden shadow-sm px-4 py-5 sm:px-6">
          <div className="border-b border-gray-100 pb-3">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Step 1</h3>
            <p className="mt-1 text-sm text-gray-500">Choose from one of our many great assessments.</p>
          </div>
          <fieldset>
            <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
              {
                surveyJsSurveyTypes.map(({id, description, is_internal, name, version, type_name: title, updated_at}) => {
                  const selected = selectedSurveyName === name;
                  const canSelect = true;

                  return (
                    <Tippy key={`${title}_${id}`} disabled={!user.admin} content={(
                      <>
                        <strong>Admin only</strong>
                        <div>ID: {id}</div>
                        <div>Version: {version}</div>
                        <div>Internal: {is_internal ? 'Internal Only' : 'PUBLIC'}</div>
                        <div>Updated: {dateFormat(updated_at, 'yyyy-mm-dd hh:mm Z')}</div>
                      </>
                    )}>
                      <label onClick={() => {
                        setSurveyType(title);
                        setSelectedSurveyName(name);
                        setSurveySource('surveyjs');
                      }} className={
                        classNames(
                          selected ? "border-green-500 ring-2 ring-green-500 relative flex rounded-lg border bg-white p-4 shadow-sm focus:outline-none" : "relative flex rounded-lg border bg-white p-4 shadow-sm focus:outline-none",
                          canSelect ? 'opacity-100 cursor-pointer' : 'opacity-50',
                        )
                      }>
                        <span className="flex flex-1">
                          <span className="flex flex-col w-full justify-between">
                            <div className="flex justify-between items-center w-full text-sm font-medium text-gray-900">
                              <div>{name}</div>
                              { selected && (<CheckCircleIcon className="h-5 w-5 text-green-600" />) }
                            </div>
                            <span className="mt-1 flex items-center text-sm text-gray-500">{description}</span>
                            <span className="mt-6 flex items-center justify-between">
                              <span className={'capitalize text-purple-700 border-purple-300 bg-purple-100 text-sm font-medium px-2 p-0.5 rounded-full'}>
                                {title}
                              </span>
                            </span>
                          </span>
                        </span>
                      </label>
                    </Tippy>
                  )
                })
              }
            </div>
          </fieldset>
        </div>

        <div className="border-b border-gray-200 bg-white rounded-lg overflow-hidden shadow-sm px-4 py-5 sm:px-6">
          <div>
            <div className="border-b border-gray-100 pb-3">
              <div className="flex justify-between items-center">
                <h3 className="text-lg font-medium leading-6 text-gray-900">Step 2</h3>
                <span className="sm:w-1/2 text-right text-sm text-red-500">{ formErrors.step2 }</span>
              </div>
              <p className="mt-1 text-sm text-gray-500">Upload or add participants to your assessment.</p>
            </div>
            <div>
              <div className="mt-5 sm:flex justify-center sm:h-96 w-full sm:divide-x sm:space-x-5">
                <div className="flex flex-col justify-end h-full w-1/2">
                  <div className="sm:flex items-center justify-between mt-0.5 mb-1">
                    <p className="block text-sm font-medium text-gray-700">
                      <span className="sm:inline hidden">Upload CSV list</span>
                      <span className="sm:hidden inline">CSV</span>
                    </p>
                    <p className="cursor-pointer text-sm text-gray-600 hover:text-gray-800 transition-colors" onClick={() => window.open('/api/v1/internal/surveys/download_sample.csv', '_target')}>
                      Download a sample CSV
                    </p>
                  </div>
                  <FileUploader
                    classes="h-full"
                    fileOrFiles={fileOrFiles}
                    onTypeError={(ev) => console.log('type error', ev)}
                    name="csv_file"
                    onDrop={updateParticipantsList}
                    onSelect={updateParticipantsList}
                    hoverTitle=""
                    dropMessageStyle={{backgroundColor: 'rgb(59, 130, 246)'}}
                  >
                    <div className={classNames('flex justify-center h-full rounded-md border-dashed border-2 px-6 pt-5 pb-6', formErrors.step2 ? 'border-red-300' : null)}>
                      <div className="inline-flex items-center">
                        <div>
                          <ChartBarSquareIcon className="mx-auto h-12 w-12 text-gray-400" />
                          <div className="flex text-sm text-gray-600">
                            { csvFile ? (
                              <>
                                <p>Selected file {csvFile.name}</p>
                                <label htmlFor="file-upload" className="relative cursor-pointer rounded-md bg-white font-medium text-blue-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-500 focus-within:ring-offset-2 hover:text-blue-500">
                                  <span className="pl-1">or change the selected file</span>
                                </label>
                              </>
                            ) : (
                              <>
                                <label htmlFor="file-upload" className="relative cursor-pointer rounded-md bg-white font-medium text-blue-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-500 focus-within:ring-offset-2 hover:text-blue-500">
                                  <span>Upload a file</span>
                                </label>
                                <p className="pl-1 hidden sm:block">or drag and drop</p>
                              </>
                            )}
                            <input ref={fileUploaderRef} hidden type="file" name="participants_list" onChange={updateParticipantsList} />
                          </div>
                          <p className="text-xs text-gray-500">CSV up to 10MB</p>
                        </div>
                      </div>
                    </div>
                  </FileUploader>
                </div>
                <div className="sm:pl-5 grow max-w-1/3 h-[104%] max-w-[43%]">
                  <label className="text-left block text-sm font-medium text-gray-700">Choose from existing Survey</label>
                  <div className="h-[95%] overflow-scroll h-full">
                    {
                      !pastSurveys.length && (
                        <div>
                          <DocumentPlusIcon className="h-12 w-12 text-gray-900 mx-auto" />
                          <span className="mt-2 block text-sm font-medium text-gray-900">No other surveys</span>
                        </div>
                      )
                    }
                    <fieldset>
                      <div className="space-y-4">
                        <RadioGroup value={!participantsFileData && selected} onChange={setSelected}>
                          <RadioGroup.Label className="sr-only"> Past surveys </RadioGroup.Label>
                          <div className="space-y-4">
                            {pastSurveys.map((survey) => (
                              <RadioGroup.Option
                                onClick={() => setParticipantsBySurvey(survey)}
                                key={`${survey.name}_${survey.id}`}
                                value={survey}
                                className={({ checked, active }) =>
                                  classNames(
                                    checked ? 'border-transparent' : 'border-gray-300',
                                    active && participantsBySurvey?.id === survey.id ? 'border-blue-500 ring-2 ring-inset ring-blue-500' : '',
                                    'relative block cursor-pointer rounded-lg border bg-white px-2 py-1 md:px-3 sm:py-2 shadow-sm focus:outline-none flex justify-between'
                                  )
                                }
                              >
                                {({ active, checked }) => (
                                  <>
                                    <span className="flex items-center">
                                      <span className="flex flex-col text-sm">
                                        <RadioGroup.Label as="span" className="font-medium text-gray-900">
                                          {survey.name}
                                        </RadioGroup.Label>
                                        <RadioGroup.Description as="span" className="text-gray-700">
                                          <Tippy content="Number of assessments in this survey" placement="bottom-start">
                                            <div className="flex space-x-1 text-md font-bold items-center">
                                              <CalculatorIcon className="h-4 w-4" />
                                              <span>{survey.assess_count}</span>
                                            </div>
                                          </Tippy>
                                        </RadioGroup.Description>
                                      </span>
                                    </span>
                                    <RadioGroup.Description
                                      as="span"
                                      className="flex flex-col justify-center"
                                    >
                                      <SurveyTypeBadge survey={survey} />
                                    </RadioGroup.Description>
                                    <span
                                      className={classNames(
                                        active ? 'border' : 'border-2',
                                        checked ? 'border-blue-500' : 'border-transparent',
                                        'pointer-events-none absolute -inset-px rounded-lg'
                                      )}
                                      aria-hidden="true"
                                    />
                                  </>
                                )}
                              </RadioGroup.Option>
                            ))}
                          </div>
                        </RadioGroup>
                      </div>
                    </fieldset>
                  </div>
                </div>
                <div className="w-1/3 h-full">
                  <form className="h-full" autoComplete="false" onSubmit={addAdhocParticipant}>
                    <div className="w-full sm:px-5 flex flex-col justify-between h-full">
                      <div>
                        <label className="block text-sm font-medium text-gray-700 text-left w-full">Manually add participants</label>
                        <div className="w-full flex flex-col space-y-4">
                          <input placeholder="First name" required type="text" name="first_name" id="first-name" className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm" />
                          <input placeholder="Last name" required type="text" name="last_name" id="last-name" className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm" />
                          <input placeholder="Email address" required id="email" name="email" type="email"  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm" />
                          <input placeholder="Group number" required type="number" name="group_number" min={1} id="group_number" className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm" />
                          <select 
                            placeholder="Assessment Type" 
                            required 
                            id="type" 
                            name="assessment_type"
                            onChange={(e) => {
                              const form = e.target.form;
                              const subjectField = form.querySelector('#subject_email');
                              if (e.target.value.toLowerCase() === 'leader') {
                                subjectField?.classList.remove('hidden');
                                subjectField?.setAttribute('required', 'required');
                              } else {
                                subjectField?.classList.add('hidden');
                                subjectField?.removeAttribute('required');
                                if (subjectField) subjectField.value = '';
                              }
                            }}
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                          >
                            <option defaultChecked>Employee</option>
                            <option>Leader</option>
                          </select>
                          <input 
                            placeholder="Subject's Email" 
                            id="subject_email" 
                            name="subject_email" 
                            type="email"
                            className="hidden block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm" 
                          />
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="mt-3 w-full flex justify-center space-x-2 items-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
                      >
                        <UserPlusIcon className="h-5 w-5 text-white" />
                        <span>Add Participant</span>
                      </button>
                    </div>
                  </form>
                </div>
                <button hidden data-survey-target="submit" type="submit">Update</button>
              </div>
            </div>
            {
              hasParticipants && (
                <div>
                  <div className="mt-8 flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        <table className="min-w-full divide-y divide-gray-300">
                          <thead>
                          <tr>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
                            >
                              First Name
                            </th>
                            <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                              Last Name
                            </th>
                            <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                              Email
                            </th>
                            <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                              Group
                            </th>
                            <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                              Type
                            </th>
                            <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                              Subject
                            </th>
                            <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                              Source
                            </th>
                            <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900"></th>
                          </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200">
                          { participants.csv.map((row, idx) => {
                            const {first_name, last_name, email, group, type} = row;
                            const error = row[5]?.error;
                            // Use same key format as duplicate check
            const key = type?.toLowerCase() === 'leader' 
              ? `${email}_${row.subject_email}` 
              : email;
            const isDup = participantDups[key] > 1;

                            return (
                              <tr title={error} key={`csv_row_${email}`} className={classNames(error || isDup ? 'bg-red-50' : null)}>
                                <td className={classNames('whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 md:pl-0', !first_name ? 'animate-pulse italic' : null)}>
                                  {first_name || 'MISSING'}
                                </td>
                                <td className={classNames('whitespace-nowrap py-4 px-3 text-sm text-gray-500', !last_name ? 'animate-pulse italic' : null)}>{last_name || 'MISSING'}</td>
                                <td className={classNames('whitespace-nowrap py-4 px-3 text-sm text-gray-500', !email ? 'animate-pulse italic' : null)}>{email || 'MISSING'}</td>
                                <td className={classNames('whitespace-nowrap py-4 px-3 text-sm text-gray-500', !group ? 'animate-pulse italic' : null)}>{group || 'MISSING'}</td>
                                <td className={classNames('whitespace-nowrap py-4 px-3 text-sm text-gray-500 capitalize', !type ? 'animate-pulse italic' : null)}>{type || 'MISSING'}</td>
                                <td className={classNames('whitespace-nowrap py-4 px-3 text-sm text-gray-500', !type ? 'animate-pulse italic' : null)}>
                                  {type?.toLowerCase() === 'leader' ? row.subject_email || 'MISSING SUBJECT' : '-'}
                                </td>
                                <td className={classNames('whitespace-nowrap py-4 px-3 text-sm text-gray-500 capitalize font-bold', !type ? 'animate-pulse italic' : null)}>CSV</td>
                                <td className={classNames('whitespace-nowrap py-4 px-3 text-sm text-gray-500 capitalize', !type ? 'animate-pulse italic' : null)}>
                                  <button onClick={() => removeParticipant('csv', idx)}>Remove</button>
                                </td>
                              </tr>
                            )
                          })}
                          { participants.past.map(({id, group, email, first_name, last_name, type, subject_email}, idx) => {
                            // Use same key format for past assessments
                            const key = type?.toLowerCase() === 'leader' 
                              ? `${email}_${subject_email}` 
                              : email;
                            const isDup = participantDups[key] > 1;

                            return (
                              <tr className={classNames(isDup ? 'bg-red-50' : null)} key={`participant_${id}`}>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 md:pl-0">
                                  {first_name}
                                </td>
                                <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                  {last_name}
                                </td>
                                <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                  {email}
                                </td>
                                <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                  {group}
                                </td>
                                <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 capitalize">
                                  {type}
                                </td>
                                <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                  {type?.toLowerCase() === 'leader' ? subject_email || '-' : '-'}
                                </td>
                                <td className="whitespace-nowrap py-4 px-3 font-bold text-sm text-gray-500 capitalize">
                                  <a href={`/survey/${participantsBySurvey.id}`} target='_blank'>{participantsBySurvey.name}</a>
                                </td>
                                <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 capitalize">
                                  <button onClick={() => removeParticipant('past', idx)}>Remove</button>
                                </td>
                              </tr>
                            )
                          })}
                          {
                            participants.adhoc.map(({first_name, last_name, email, group_number, assessment_type, subject_email}, idx) => {
                              // Use same key format for adhoc assessments
                              const key = assessment_type?.toLowerCase() === 'leader' 
                                ? `${email}_${subject_email}` 
                                : email;
                              const isDup = participantDups[key] > 1;

                              return (
                                <tr className={classNames(isDup ? 'bg-red-50' : null)} key={`adhoc_participant_${email}`}>
                                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 md:pl-0">
                                    {first_name}
                                  </td>
                                  <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                    {last_name}
                                  </td>
                                  <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                    {email}
                                  </td>
                                  <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                    {group_number}
                                  </td>
                                  <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 capitalize">
                                    {assessment_type}
                                  </td>
                                  <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                    {assessment_type?.toLowerCase() === 'leader' ? subject_email || '-' : '-'}
                                  </td>
                                  <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 capitalize font-bold">
                                    Manual
                                  </td>
                                  <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 capitalize">
                                    <button onClick={() => removeParticipant('adhoc', idx)}>Remove</button>
                                  </td>
                                </tr>
                              )
                            })
                          }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
          </div>
        </div>

        <div className="border-b border-gray-200 bg-white rounded-lg overflow-hidden shadow-sm px-4 py-5 sm:px-6">
          <div className="border-b border-gray-100 pb-3 mb-3">
            <div className="flex justify-between items-center">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Step 3</h3>
              <span className="text-sm text-red-500">{ formErrors.step3 }</span>
            </div>
            <p className="mt-1 text-sm text-gray-500">Assessment details</p>
          </div>

          <div>
            <label htmlFor="survey_name" className="block text-sm font-medium text-gray-700">Name</label>
            <div className="mt-1">
              <input autoComplete='off' onChange={({target}) => setSurveyName(target.value)} type="text" name="survey_name" id="survey_name" className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm sm:w-1/4" placeholder="Name of your survey..." />
            </div>
          </div>

        </div>

        <div className="border-b border-gray-200 bg-white rounded-lg overflow-hidden shadow-sm px-4 py-5 sm:px-6">
          <div className="border-b border-gray-100 pb-3 mb-3">
            <div className="flex justify-between items-center">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Step 4</h3>
              <span className="text-sm text-red-500">{ formErrors.step4 }</span>
            </div>
            <p className="mt-1 text-sm text-gray-500">Schedule a time for your assessment to go live</p>
          </div>

          <div className="flex justify-around">
            <div>
              <h2 className="text-sm font-medium text-gray-900">Start the survey</h2>
              <div className={classNames(
                `space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10`,
                !surveyStartsOn ? 'sm:h-full' : ''
              )}>
                <div className="flex items-center">
                  <input type="radio" name="starts_on" defaultChecked onChange={() => setSurveyStartsOn(null)} className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-500" />
                  <label className="ml-3 block text-sm font-medium text-gray-700">Manually</label>
                </div>

                <div className="flex items-center">
                  <input type="radio" name="starts_on" onChange={() => setSurveyStartsOn(new Date())} className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-500" />
                  <label className="ml-3 block text-sm font-medium text-gray-700">Date and time</label>
                </div>
              </div>

              {
                surveyStartsOn && (
                  <fieldset className="mt-2">
                    <input
                      step={60*60}
                      onChange={({target: {value}}) => setSurveyStartsOn(value) }
                      onFocus={() => setStartsOnMinDate(formatDate(new Date()))}
                      min={startsOnMinDate}
                      value={surveyStartsOn}
                      type="datetime-local"
                      name="start_on"
                      className="block w-3/4 sm:w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                    />
                  </fieldset>
                )
              }
            </div>
            <div className="w-1/3">
              <h2 className="text-sm font-medium text-gray-900">When to end the survey</h2>
              <SurveyEndsOnRange onChange={(value) => setSurveyEndsOn(value)} />
            </div>
            <div className="w-1/3 flex flex-col justify-between">
              <h2 className="text-sm font-medium text-gray-900 flex items-center space-x-2">
                <span>Add a deadline (Eastern)</span>
                <Tippy content="This will add copy to the assessment invitation email noting the deadline. This is not required." placement="top-start">
                  <InformationCircleIcon className="text-gray-500 h-5 w-5" />
                </Tippy>
              </h2>
              <InputDateTime onChange={({target: {value}}) => setDeadline(value)} />
            </div>
          </div>
        </div>

        <div className="border-b border-gray-200 bg-white rounded-lg overflow-hidden shadow-sm px-4 py-5 sm:px-6">
          <div className="border-b border-gray-100 pb-3 mb-3">
            <div className="flex justify-between items-center">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Step 5</h3>
            </div>
            <p className="mt-1 text-sm text-gray-500">Would you like to automatically send results to participants when they complete the assessment?</p>
          </div>
          <div className="space-y-6">
            <SwitchToggle 
              onChange={setSurveyAutoSendResults} 
              disabled={surveyType === 'behavioral'}
              checked={surveyType === 'behavioral' ? true : surveyAutoSendResults}
              description={
                surveyType === 'behavioral' 
                  ? "Behavioral assessments always send completion notification emails automatically" 
                  : "When a participant completes the survey, they will get an email with their results PDF attached."
              } 
            />

            {surveyType === 'manager_review' && (
              <SwitchToggle
                onChange={setCreateEmployeeSurveys}
                checked={createEmployeeSurveys}
                description="When enabled, employees will also receive surveys. When disabled, only managers will receive surveys about their employees."
              />
            )}
          </div>
        </div>

        <input type="hidden" value="complete" name="create_survey" />
        <span className="text-sm text-red-500">{ formErrors.submitError }</span>
        <div className="flex justify-end">
          <button
            disabled={savingSurvey}
            onClick={() => !savingSurvey && saveSurvey()}
            className={
              classNames(
                savingSurvey ? 'hover:bg-gray-700 focus:ring-gray-500 bg-gray-500 cursor-not-allowed' : 'hover:bg-blue-700 focus:ring-blue-500 bg-blue-500',
                'w-full sm:w-auto inline-flex justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2'
              )
            }>
            <div className="flex items-center space-x-2">
              {savingSurvey && (<Spinner textColor="white" />)}
              <span>Create Survey</span>
            </div>
          </button>
        </div>
      </div>
    </SurveyProvider>
  )
}
