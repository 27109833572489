import React, {useContext} from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  Outlet
} from "react-router-dom";
import Dashboard from './Dashboard';
import Survey from './Survey';
import {
  getSurveys,
  getSurveyAssessments,
  getSurvey,
  getMe,
  getNewSurvey,
  getTeamReports,
  getDocuments,
  getQuiz,
  getQuizzes,
} from './fetchers';
import SurveyNew from './SurveyNew';
import Notifications from './Notifications';
import NotificationProvider from './contexts/ContextNotification';
import {useLoaderData} from 'react-router';
import SurveyProvider from './contexts/ContextSurvey';
import WhistlePng from '../../assets/images/whistle.png';
import UserProvider, {UserContext} from './contexts/ContextUser';
import {CalendarDaysIcon} from '@heroicons/react/24/solid';
import AssessmentsProvider from "./contexts/ContextAssessments";
import TeamReportNew from "./TeamReportNew";
import ResourcesDrawer from "./ResourcesDrawer";
import QuizEditor from "./QuizEditor";
import Quizzes from "./Quizzes";

const notDevelopment = process.env.NODE_ENV && process.env.NODE_ENV !== 'development';

setTimeout(() => {
  // we need to clean up flash messages from rails since we don't do any "true" reloads.
  const flash = document.querySelector('#flash');
  if (flash.children.length) {
    Array.from(flash.children).forEach((c) => flash.removeChild(c));
  }
}, 10000);

const ErrorBoundary = () => {
  if (!notDevelopment) return null;

  return (
    <div className="mx-auto w-1/2 mt-20">
      <h2>Oh boy.</h2>
      <h4>That's not good</h4>
      <h4>Are you in the right account?</h4>
      <h4>Give it a refresh?</h4>
      <img src={WhistlePng} className="mx-auto w-1/2"  alt={"Error Whistle"}/>
    </div>
  );
};

function Container({children}) {
  return (
    <div className="max-w-7xl mx-auto">{children}</div>
  )
}

function Layout() {
  const {user} = useLoaderData();
  
  return (
    <div className="pb-16">
      <div id="app" style={{height: '100vh'}}>
        <NotificationProvider>
          <UserProvider defaultUser={user}>
            <Notifications notifications={[]} />
            <div className="px-6 h-12 bg-white shadow-sm w-full flex justify-end">
              <div className="flex items-center space-x-3 divide-x-3 divide-gray-400">
                <a href="/book_time"
                   className="sm:hidden text-sm flex justify-center items-center space-x-2 sm:block cursor-pointer hover:text-white hover:bg-blue-400 hover:ring-blue-200 transition-all text-white p-2 rounded-full bg-orange-600 text-center shadow-lg ring-2 ring-orange-300">
                  <CalendarDaysIcon className="h-4 w-4" />
                </a>
                <a href="/book_time"
                   className="hidden text-sm flex justify-center items-center space-x-2 sm:block cursor-pointer hover:text-white hover:bg-blue-400 hover:ring-blue-200 transition-all text-white px-3 py-1 rounded-full bg-orange-600 text-center shadow-lg ring-2 ring-orange-300">
                  <CalendarDaysIcon className="mb-0.5 h-5 w-5 inline" />
                  <span className="">Schedule a call</span>
                </a>
              </div>
            </div>
            <Outlet />
          </UserProvider>
        </NotificationProvider>
      </div>
    </div>
  );
}

const router = () => createBrowserRouter([
  {
    element: <Layout />,
    errorElement: <ErrorBoundary />,
    loader: async ({request}) => {
      const user = await getMe(request.signal);
      return {user};
    },
    children: [
      {
        path: "/",
        element: (
          <Container>
            <Dashboard />
          </Container>
        ),
        loader: async ({request}) => {
          const url = new URL(request.url);
          const [surveys, teamReports] = await Promise.all([
            getSurveys(request.signal, url.searchParams.get("sp")),
            getTeamReports(request.signal, url.searchParams.get("trp")),
          ]);
          return {surveys, teamReports};
        },
      },
      {
        path: "/resources",
        element: <ResourcesDrawer />,
        loader: async({request}) => {
          const url = new URL(request.url);
          if (!url.searchParams.get('ctl')) return {documents: [], pages: {}, restricted: true};
          return await getDocuments(request.signal, url.searchParams.get('drp'), url.searchParams.get('ctl'))
        }
      },
      {
        path: "/survey/new",
        element: (
          <Container>
            <SurveyNew />
          </Container>
        ),
        loader: async({request}) => {
          return getNewSurvey(request.signal);
        }
      },
      {
        path: "/survey/:surveyId",
        element: <SurveyHoc />,
        loader: async({request, params}) => {
          const survey = await getSurvey(request.signal, params.surveyId);
          const assessments = await getSurveyAssessments(request.signal, params.surveyId);
          return {survey, assessments};
        },
      },
      {
        path: "/team_reports/new",
        element: (
          <Container>
            <TeamReportNew />
          </Container>
        ),
        loader: async({request}) => {
          const surveys = await getSurveys(request.signal);
          return {surveys};
        }
      },
      {
        path: '/quizzes',
        element: (
          <Container>
            <AdminRequired>
              <Quizzes />
            </AdminRequired>
          </Container>
        ),
        loader: async({request}) => {
          return await getQuizzes(request.signal);
        },
      },
      {
        path: '/quizzes/:quizId',
        element: (
          <AdminRequired>
            <QuizEditor />
          </AdminRequired>
        ),
        loader: async({request, params}) => {
          return await getQuiz(request.signal, params.quizId)
        },
      }
    ]
  }
]);

function SurveyHoc() {
  const {survey, assessments} = useLoaderData();

  return (
    <SurveyProvider survey={survey}>
      <AssessmentsProvider assessments={assessments}>
        <Container>
          <Survey assessments={assessments} />
        </Container>
      </AssessmentsProvider>
    </SurveyProvider>
  )
}

export function AdminRequired({children}) {
  const {user} = useContext(UserContext);

  if (!user.admin) return null;

  return children;
}

export default (props) => {
  return <RouterProvider router={router(props)} />;
};
