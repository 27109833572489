import React, {useEffect, useContext} from 'react';
import {Link} from 'react-router-dom';
import {useLoaderData, useNavigate} from 'react-router';
import {
  CalendarDaysIcon,
  DocumentPlusIcon, ListBulletIcon,
  UserGroupIcon,
  UserIcon
} from '@heroicons/react/24/outline';
import {ArrowRightIcon, DocumentDuplicateIcon} from '@heroicons/react/24/solid';
import CoinsIcon from "./icons/CoinsIcon";
import Pages from "./Pages";
import TeamReports from "./TeamReports";
import {AdminRequired} from "./App";
import {UserContext} from "./contexts/ContextUser";

export default function Dashboard() {
  const {surveys: { surveys, pages: surveyPages } = {pages: {}}} = useLoaderData();
  const {user} = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    // this is not a great solution but we want to softly navigate to the dashbaord when clicking the
    // CC logo as it is outside the react app context
    document.querySelector('#home').onclick = function(event) {
      event.preventDefault();
      navigate('/');
    }
  }, []);

  function renderStatusPill(status) {
    let color = null;

    status = status.split('_').join(' ');

    switch(status) {
      case 'active':
        color = 'bg-green-100 text-green-800';
        break;
      case 'paid':
        color = 'bg-blue-100 text-blue-800';
        break;
      case 'draft':
        color = 'bg-yellow-100 text-yellow-800'
        break;
      default:
        color = 'bg-gray-100 text-gray-800';
        break;
    }

    return (
      <span className={`${color} inline-block flex-shrink-0 rounded-full px-2 py-0.5 text-xs font-medium capitalize`}>
        {status}
      </span>
    );
  }

  return (
    <>
      <div className="my-8 pb-10 mx-5">
        <div className="sm:flex sm:justify-between sm:items-center">
          <h2>
            Welcome, {user.first_name}
          </h2>
          <AdminRequired>
            <Link to="/quizzes">
              <button type="button" className="bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 w-full mt-4 sm:mt-0 sm:w-auto flex justify-center items-center space-x-2 rounded-md px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-purple-400 focus:ring-offset-2">
                <ListBulletIcon className="h-5 w-5 text-white" />
                <span>Quizzes</span>
              </button>
            </Link>
          </AdminRequired>

        </div>
        <Link className="cursor-pointer hover:text-white hover:bg-orange-400 transition-all z-40 text-white h-12 w-12 sm:w-auto p-3 sm:px-4 space-x-2 fixed bottom-5 left-5 rounded-full bg-blue-600 sm:flex justify-center items-center font-bold text-center shadow-lg ring-4 ring-blue-300" to="/resources">
          <DocumentDuplicateIcon className="h-6 w-6" />
          <span className="hidden sm:block ml-2">Resources</span>
        </Link>
        <div className="mb-15">
          <div className="flex justify-between items-center">
            <div className="sm:flex-auto my-5">
              <h1 className="text-xl font-semibold text-gray-900">Team Reports</h1>
              <p className="mt-2 text-sm text-gray-700">Your purchased team reports</p>
            </div>
            <Link to="/team_reports/new">
              <button type="button" className="w-full mt-4 sm:mt-0 sm:w-auto flex justify-center items-center space-x-2 rounded-md border border-transparent bg-orange-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-400 focus:ring-offset-2">
                <DocumentPlusIcon className="h-5 w-5 text-white" />
                <span>Team Report</span>
              </button>
            </Link>
          </div>
          <TeamReports />
        </div>
        <div className="flex justify-between items-center">
          <div className="sm:flex-auto my-5">
            <h1 className="text-xl font-semibold text-gray-900">Surveys</h1>
            <p className="mt-2 text-sm text-gray-700">All your surveys</p>
          </div>
          <Link to="/survey/new">
            <button type="button" className="w-full mt-4 sm:mt-0 sm:w-auto flex justify-center items-center space-x-2 rounded-md border border-transparent bg-blue-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2">
              <DocumentPlusIcon className="h-5 w-5 text-white" />
              <span>Create Survey</span>
            </button>
          </Link>
        </div>
        {
          !surveys.length && (
            <Link to="/survey/new">
              <button
                type="button"
                className="relative block w-3/5 mx-auto mt-10 rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                <DocumentPlusIcon className="mx-auto h-12 w-12 text-gray-400" />
                <span className="mt-2 block text-sm font-medium text-gray-900">Create your first survey!</span>
              </button>
            </Link>
          )
        }

        <div className='grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3'>
          {
            surveys.map(({assess_count, name, created_at, created_by, status, id, used_tokens}) => {
              return (
                <li key={`${id}_${name}`} className="list-none rounded-lg bg-white shadow">
                  <div className="divide-y divide-gray-200">
                    <div className="flex w-full items-center justify-between space-x-6 p-6">
                      <div className="flex-1 truncate">
                        <div className="flex items-center space-x-3">
                          <h3 className="truncate text-sm font-medium text-gray-900">
                            {name}
                          </h3>
                          {renderStatusPill(status)}
                        </div>
                        <div className="flex items-center space-x-1 mt-1">
                          <CalendarDaysIcon className="h-4 w-4 text-gray-900" />
                          <span className="truncate text-sm text-gray-500">{new Intl.DateTimeFormat('en-US').format(new Date(created_at))}</span>
                        </div>
                        <div className="flex items-center space-x-1 mt-1">
                          <UserIcon className="h-4 w-4 text-gray-900" />
                          <span className="truncate text-sm text-gray-500">{created_by}</span>
                        </div>
                      </div>
                      <div className="flex-col items-center">
                        <div className="flex items-center space-x-1">
                          <UserGroupIcon className="w-7 h-7" />
                          <div className="text-3xl font-semibold tracking-tight text-gray-900">
                            {assess_count}
                          </div>
                        </div>
                        <div className="flex items-center space-x-1">
                          <CoinsIcon playable className="w-7 h-7" />
                          <div className="text-3xl font-semibold tracking-tight text-gray-900">
                            {used_tokens}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="-mt-px flex divide-x divide-gray-200">
                        <div className="-ml-px flex w-0 flex-1">
                          <Link to={`survey/${id}`} className="relative inline-flex w-0 flex-1 items-center justify-end pr-4 rounded-br-lg border border-transparent py-4 text-sm font-medium text-gray-700 hover:text-gray-500">
                            <span className="mr-3">View</span>
                            <ArrowRightIcon className="h-5 w-5 text-gray-400" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              )
            })
          }
        </div>
        <br />
        <Pages queryKey='sp' maxPage={surveyPages.pages} />
      </div>
    </>
  )
}
